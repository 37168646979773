<template>
    <social-sharing :url="bookingUrl" :title="'I am going to ' + eventTitle + ' event. Come to see it here:'" :type="'popup'" inline-template>
        <div>
            <div class="text-right">
                <b-button class="round-button" @click.stop>
                    <network network="email">
                        <i class="fa fa-envelope"></i>
                    </network>
                </b-button>
                <b-button class="round-button" @click.stop>
                    <network network="facebook">
                        <i class="fa fa-facebook"></i>
                    </network>
                </b-button>
                <b-button class="round-button" @click.stop>
                    <network network="twitter">
                        <i class="fa fa-twitter"></i>
                    </network>
                </b-button>
            </div>
        </div>
    </social-sharing>
</template>

<script>
    export default {
        props: ['bookingUrl', 'eventTitle'],
        name: "socialMediaBtn"
    }
</script>

<style>
    .round-button  {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
</style>
